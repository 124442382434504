<template>
  <div id="meet-concluding">
    <div class="m-0 mb-3">
      <b class="robo-18-500 fw-bold">Biên bản họp</b>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Số:</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder=""
          v-model="form.code"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Tiêu đề Chính</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder=""
          v-model="form.title"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Tiêu đề Phụ</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder=""
          v-model="form.sub_title"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Kính ngữ</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder="Vd: Kính gửi..."
          v-model="form.send_to"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Thời gian:</label>
        <input
          type="datetime-local"
          class="form-control mt-1"
          placeholder=""
          v-model="form.meet_time"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Địa điểm</label>
        <input
          type="text"
          class="form-control mt-1"
          placeholder=""
          v-model="form.location"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Chủ trì</label>
        <vue-editor
          v-model="form.chairing_list"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-14-500 fw-bold">Thành phần tham dự</label>
        <vue-editor
          v-model="form.attendees"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="mc-introduce" class="robo-14-500 fw-bold">
          Nội dung cuộc họp
        </label>
        <vue-editor
          v-model="form.content"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </div>
    </div>
    <div class="m-0 mb-3">
      <b class="robo-14-500 fw-bold">Người kí biên bản</b>
    </div>
    <div class="m-0 mb-3 row">
      <div class="form-group col-6">
        <label for="txt-work" class="robo-14-500">Họ và tên</label>
        <input
          type="text"
          class="form-control mt-1"
          id="txt-work"
          placeholder=""
          v-model="form.sign_name"
        />
      </div>
      <div class="form-group col-6">
        <label for="txt-position" class="robo-14-500">Chức danh</label>
        <input
          type="text"
          class="form-control mt-1"
          id="txt-position"
          placeholder=""
          v-model="form.sign_label"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <input class="me-2" type="checkbox" id="nn" v-model="show_recip" /><label
        class="robo-14-500 fw-bold"
        for="mc-introduce"
        >Nơi nhận</label
      >
      <div class="form-group" v-if="show_recip">
        <vue-editor
          v-model="form.recipients"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
export default {
  name: 'MeetConcludingForm',
  props: ['concluding'],
  components: { VueEditor },
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block', 'link'],
        [
          {
            align: ''
          },
          {
            align: 'center'
          },
          {
            align: 'right'
          },
          {
            align: 'justify'
          }
        ]
      ],
      form: {
        code: '',
        conclusion: '',
        send_to: '',
        meet_time: this.moment().format('YYYY-MM-DDTHH:mm'),
        location: '',
        chairing_list: '',
        attendees: '',
        content: '',
        recipients: '',
        sign_name: '',
        sign_label: '',
        title: '',
        sub_title: ''
      },
      show_recip: false
    }
  },
  watch: {
    form: {
      handler (data) {
        this.$emit('changeForm', data)
      },
      deep: true
    },
    concluding: {
      handler (data) {
        this.form = {
          code: data.code,
          conclusion: data.conclusion,
          send_to: data.send_to,
          meet_time: this.moment(data.meet_time).format('YYYY-MM-DDTHH:mm'),
          location: data.location,
          chairing_list: data.chairing_list,
          attendees: data.attendees,
          content: data.content,
          recipients: data.recipients,
          sign_name: data.sign_name,
          sign_label: data.sign_label,
          title: data.title,
          sub_title: data.sub_title
        }
        if (data.recipients) this.show_recip = true
      },
      deep: true
    },
    show_recip (d) {
      if (!d) this.form.recipients = ''
    }
  },
  methods: {}
}
</script>

<style scoped>
.in {
  width: 190px;
  height: 40px;
}
.ml-24 {
  margin-right: 24px;
}
</style>
