<template>
    <div class="row">
        <div class="col-md-6 px-3" v-show="is_owner">
            <MeetConcludingForm @changeForm="changeForm" :concluding="concluding"/>
        <div class="text-end">
            <button class="btn px-5 btn-primary bg-pri" @click="submit" type="button">Lưu</button>
        </div>
        </div>
        <div class="d-none d-md-block" :class="is_owner ? 'col-md-6' : 'col-12'">
          <div :class="is_owner ? '' : 'container'">
            <MeetConcludingTemplate :form="form" :save_pdf="save_pdf" @onSubmiting="onSubmiting" @exportError="exportError" :meet_room="meet_room"/>
          </div>
        </div>
    </div>
</template>

<script>
import MeetConcludingForm from './MeetConcludingForm.vue'
import MeetConcludingTemplate from './MeetConcludingTemplate.vue'
export default {
  name: 'MeetConcluding',
  components: { MeetConcludingForm, MeetConcludingTemplate },
  props: ['meet_room', 'is_owner', 'rf_concluding'],
  data () {
    return {
      form: {
        code: '',
        conclusion: '',
        send_to: '',
        meet_time: this.moment().format('YYYY-MM-DDTHH:mm'),
        location: '',
        chairing_list: '',
        attendees: '',
        content: '',
        recipients: '',
        sign_name: '',
        sign_label: '',
        title: '',
        sub_title: ''
      },
      concluding: null,
      save_pdf: 0
    }
  },
  watch: {
    meet_room: {
      handler (data) {
        if (data) this.getTodayMeetRoomConcluding(data.id)
      },
      deep: true
    },
    rf_concluding (c) {
      if (this.meet_room) this.getTodayMeetRoomConcluding(this.meet_room.id)
    }
  },
  methods: {
    changeForm (data) {
      this.form = data
    },
    submit () {
      this.loading = true
      this.save_pdf++
    },
    formatHmDMY (date) {
      const string = window.moment(date).format('HH:mm DD/MM/YYYY')
      string.split(' ').join(' ngày ')
      return string
    },
    onSubmiting (file) {
      console.log(file)
      if (!this.meet_room) return
      if (this.concluding) {
        this.updateMeetRoomConcluding(file)
      } else {
        this.createMeetRoomConcluding(file)
      }
    },
    async createMeetRoomConcluding (file = null) {
      const self = this
      if (!self.meet_room) return
      const params = { ...this.form }
      params.meet_time = window.moment(self.form.meet_time).format('HH:mm DD-MM-YYYY')
      if (file) params.file = file
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, (params[key] || ''))
      }
      await self.$rf.getRequest('AuthRequest').createMeetRoomConcluding(this.meet_room.id, formData).then((r) => {
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
        self.$emit('refresh')
        self.getTodayMeetRoomConcluding(self.meet_room.id)
      }).finally(() => {
        self.loading = false
      })
    },
    async updateMeetRoomConcluding (file = null) {
      const self = this
      if (!self.concluding) return
      const params = { ...this.form }
      params.meet_time = window.moment(self.form.meet_time).format('HH:mm DD-MM-YYYY')
      if (file) params.file = file
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key])
      }
      await self.$rf.getRequest('AuthRequest').updateMeetRoomConcluding(self.concluding.id, formData).then(() => {
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
        self.$emit('refresh')
        self.getTodayMeetRoomConcluding(self.meet_room.id)
      }).finally(() => {
        self.loading = false
      })
    },
    exportError (e) {
      console.log(e)
    },
    async getTodayMeetRoomConcluding (id) {
      const self = this
      await this.$rf.getRequest('AuthRequest').getTodayMeetRoomConcluding(id).then((r) => {
        self.concluding = r.data
      })
    }
  }
}
</script>

<style scoped>

</style>
