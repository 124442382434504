<template>
  <div style="mdt-info" v-if="meet_room">
    <div class="mb-4 border-bottom d-flex justify-content-between header-info">
      <p class="mb-2 fs-16 fw-500">{{$t('meet_room.meet_info')}}</p>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideInfo"
        class="c-pointer"
      >
        <path
          d="M6 17.9998L17.9998 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 17.9998L6 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div>
      <p class="mb-2 fs-24 fw-500 txt-black" v-if="meet_room">{{ meet_room.name }}</p>
      <p class="mb-4 fs-18 txt-black">{{ getTimeStringDetail(meet_room) }}</p>
      <div class="mb-4" v-if="meet_room.location">
        <p class="mb-2 fs-18 fw-500">{{ $t('mdt_room.lbl_location') }}</p>
        <p class="mb-2 fs-16">{{ meet_room.location }}</p>
      </div>

      <div class="mb-4" :set="(link = linkRoom(meet_room.room))">
        <p class="mb-2 fs-18 fw-500 txt-black">{{ $t('mdt_room.lbl_room_link') }}</p>
        <div class="row">
          <div class="col-6">
            <p class="mb-2 fs-16 mb-0 txt-black max-line-1">{{ link || "..." }}</p>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-end">
            <a
              href="javascript:;"
              @click="copyLink(link)"
              class="txt-pri text-decoration-underline txt-16-400 d-flex align-items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ms-2"
              >
                <path
                  d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.60003C3.60003 22 2.00003 20.4 2.00003 16.4V12.6C2.00003 8.6 3.60003 7 7.60003 7H10.6"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.6 2H15.6"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.00003 5C7.00003 3.34 8.34003 2 10 2H12.62"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 8V14.19C22 15.74 20.74 17 19.19 17"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ $t('mdt_room.lbl_copy_link') }}
            </a>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-2 fs-18 fw-500 txt-black">{{ $t('lbl_invited_members') }}</p>
        <div class="mb-3" v-if="room_members && room_members.length">
          <div
            v-for="mem in room_members"
            :key="mem.id + 'mem'"
            class="d-flex align-items-center justify-content-between item-doctor p-0 mb-3"
            :set="(status = getStatus(mem.doctor_id))"
          >
            <div
              class="d-flex align-items-center"
              :set="[(user = mem.user), (name = getUserName(mem.user))]"
            >
              <span
                v-if="user && user.avatar"
                class="img-avatar ms-2"
                :style="
                  'background-image: url(' +
                  appUtils.getImageURL(user.avatar) +
                  ')'
                "
              ></span>
              <span v-if="user && !user.avatar" class="img-avatar ms-2">{{ name.charAt(0) }}</span>
              <div>
                <p class="fs-14 fw-500 txt-black mb-0 max-line-1">{{ name }}</p>
                <p class="fs-12 text-black-50 mb-0 max-line-1">{{ getRoleName(mem.role) }}</p>
              </div>
            </div>
            <!-- <p class="mb-0 fs-14" :class="status ? status.class : ''">
              {{ status.text }}
            </p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
const statusCheckin = [
  { stt: 0, text: 'Đang chờ', class: 'text-warning' },
  { stt: 1, text: 'Đã điểm danh', class: 'text-success' },
  { stt: 2, text: 'Không tham gia', class: 'text-danger' }
]
export default {
  name: 'MeetInfo',
  props: ['meet_room', 'room_members'],
  data () {
    return {
      appUtils,
      statusCheckin
    }
  },
  methods: {
    getStatus (id) {
      let status = 0
      if (this.checkin_members && this.checkin_members?.data?.length) {
        const checkin = this.checkin_members?.data.find(
          c => parseInt(c?.doctor_id) === parseInt(id)
        )
        if (checkin) {
          status = checkin?.status
        }
      }
      return this.statusCheckin[status]
    },
    copyLink (link) {
      const url = document.createElement('input')
      document.body.appendChild(url)
      url.value = link
      url.select()
      url.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('mdt_room.lbl_copied_msg'),
        type: 'success'
      })
      url.remove()
    },
    hideInfo () {
      this.$emit('hideInfo')
    },
    getRoleName (r) {
      return this.$t(
        r === 1
          ? 'mdt_room.lbl_mems_preside'
          : r === 2
            ? 'mdt_room.lbl_mems_type_secretary'
            : 'role_member'
      )
    },
    linkRoom (room) {
      if (!room) return
      return process.env.VUE_APP_BASE_URL + '#/meet/' + room.room_name
    },
    getTimeStringDetail (room) {
      if (room && room.repeat_type) {
        if (room.repeat_type === 1) {
          const time = this.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
          return room.start_time && room.start_time !== '00:00:00'
            ? time + ' - ' + this.$t('daily')
            : ''
        }
        if (room.repeat_type === 2) {
          const time = this.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
          const r = room.start_time
            ? time +
              ' - ' +
              this.getNameOfDay(this.moment(room.date).day()) +
              ` ${this.$t('weekly')}`
            : '' +
              this.getNameOfDay(this.moment(room.date).day()) +
              ` ${this.$t('weekly')}`
          return r
        }
      } else {
        return room.start_time
          ? this.createDateTime(room.date, room.start_time).format(
            'HH:mm - DD/MM/YYYY'
          )
          : this.moment(room.date).format('DD/MM/YYYY')
      }
    },
    getNameOfDay (day) {
      return day === 0
        ? this.$t('sunday')
        : day === 1
          ? this.$t('monday')
          : day === 2
            ? this.$t('tuesday')
            : day === 3
              ? this.$t('wednesday')
              : day === 4
                ? this.$t('thursday')
                : day === 5
                  ? this.$t('friday')
                  : day === 6
                    ? this.$t('saturday')
                    : ''
    },
    createDateTime (dateStr, timeStr) {
      const date = this.moment(dateStr)
      const time = this.moment(timeStr, 'HH:mm:ss')

      date.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second')
      })
      return date
    },
    getUserName (u) {
      return u?.name || u?.email
    }
  }
}
</script>

<style scoped>
.mdt-info {
  background: #fff;
  color: black;
}
.header-info {
  width: 100%;
}
.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #476dd6;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
