<template>
  <div class>
    <div class="mb-3 border-bottom d-flex justify-content-end">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideFunctions"
        class="c-pointer"
      >
        <path
          d="M6 17.9998L17.9998 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 17.9998L6 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="text-end">
      <button
        v-if="!!concluding || isOwner"
        class="btn btn-primary bg-pri mb-3"
        @click="showModalConcluding(true)"
      >{{ $t('lbl_meeting_minutes') }}</button>
    </div>
    <div>
      <p class="mb-2 fs-18 fw-500 txt-black">{{ $t('lbl_document') }}</p>

      <div
        v-for="item in attachments.data"
        :key="item.id + 'at'"
        class="attach-item"
        @click="openDocAttach(item)"
      >
        <p class="mb-0 robo-16-500 txt-pri max-line-1">{{ item.name }}</p>
        <p class="mb-0 robo-10-400 txt-blur-gray max-line-1">
          <span>{{ $t('lbl_upload') }}: {{ formatDMY(item.created_at) }}</span>
          <span v-if="item.date">- {{ $t('lbl_meeting_date') }}: {{ formatDMY(item.date) }}</span>
        </p>
      </div>
      <div class="w-100 text-center my-3" v-if="meet_room">
        <a
          href="javascript:;"
          class="txt-pri robo-16-400"
          v-if="attachments.current_page < attachments.last_page"
          @click="getMeetRoomAttachs(meet_room.id, true)"
        >{{ $t('lbl_see_more') }}</a>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalConcluding"
      tabindex="-1"
      aria-labelledby="modalConcludingLabel"
      aria-hidden="true"
      style="z-index: 1051"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <h5 class="modal-title" id="modalConcludingTT"></h5>
            <button
              type="button"
              class="close bg-white border-0"
              ata-bs-dismiss="modal"
              aria-label="Close"
              @click="showModalConcluding()"
            >
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <MeetConcluding
              :meet_room="meet_room"
              :rf_concluding="rf_concluding"
              @refresh="showModalConcluding"
              :is_owner="isOwner"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetConcluding from './MeetConcluding.vue'
export default {
  name: 'MeetAttachments',
  props: ['meet_room', 'room_members', 'rf_concluding', 'rf_attach'],
  components: { MeetConcluding },
  data () {
    return {
      attachments: [],
      user_id: null,
      concluding: null
    }
  },
  watch: {
    meet_room: {
      handler (data) {
        this.getMeetRoomAttachs(data.id)
        this.getTodayMeetRoomConcluding(data.id)
      },
      deep: true
    },
    rf_attach: {
      handler (data) {
        if (this.meet_room) this.getMeetRoomAttachs(this.meet_room.id)
      },
      deep: true
    },
    rf_concluding: {
      handler (data) {
        console.log(data)
        if (this.meet_room) this.getTodayMeetRoomConcluding(this.meet_room.id)
      },
      deep: true
    }
  },
  computed: {
    isOwner () {
      if (this.room_members) {
        const master = this.room_members.some(m => m.user_id === this.user_id)
        return master || this.meet_room?.user_id === this.user_id
      } else {
        return this.meet_room && this.meet_room?.user_id === this.user_id
      }
    }
  },
  mounted () {
    this.user_id = this.$user?.id
    if (this.meet_room) {
      this.getMeetRoomAttachs(this.meet_room.id)
      this.getTodayMeetRoomConcluding(this.meet_room.id)
    }
  },
  methods: {
    showModalConcluding (show) {
      window.$('#modalConcluding').modal(show ? 'show' : 'hide')
    },
    async getMeetRoomAttachs (id, isNext) {
      const self = this
      if (!id) return
      let page = 1
      if (!isNext) {
        self.attachments = []
      } else {
        page = this.params_disease.page + 1
      }
      const params = {
        date: this.moment().format('DD-MM-YYYY'),
        page: page
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getMeetRoomAttachs(id, params)
        .then(r => {
          if (r && r.data) {
            self.attachments.current_page = r.data.current_page
            if (!isNext) {
              self.attachments = r.data
            } else {
              if (r.data?.count) {
                const old = self.attachments.data
                const newObj = r.data.data
                self.attachments.data = old.concat(newObj)
              }
            }
          }
        })
    },
    openDocAttach (doc) {
      window.open(doc.url)
    },
    formatDMY (dt) {
      return this.moment(dt).format('DD/MM/YYYY')
    },
    hideFunctions () {
      this.$emit('hideFunctions')
    },
    async getTodayMeetRoomConcluding (id) {
      const self = this
      await this.$rf
        .getRequest('AuthRequest')
        .getTodayMeetRoomConcluding(id)
        .then(r => {
          self.concluding = r.data
        })
    }
  }
}
</script>

<style scoped>
.attach-item {
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 16px;
}
</style>
